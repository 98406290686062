[data-component = Manage] {
  .admin_header li {
    list-style: none;
  }

  /*头部导航*/
  .admin_header {
    width: 100%;
    height: 8vh;
    position: relative;
    background: #333333;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }

  #admin_wrap {
    position: relative;
    width: 7vh;
    height: 7vh;
    margin: 0 20px;
    background: #AD4CC7;
    box-shadow: 0 0 1px #CC6AE7;
    border-radius: 50%;
    cursor: pointer;
    transition: 0.5s;
  }

  #admin_wrap  img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    border-radius: 50%
  }

  /*头部菜单*/
  .menu-ul {
    height: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    position: relative;
  }

  .menu-ul .menu-item {
    position: relative;
    color: #DBDBDB;
    height: 100%;
    font-size: 18px;
    line-height: 8vh;
    border-bottom: 0 solid #FFF;
    float: right;
    padding: 0 15px;
    text-align: center;
    cursor: pointer;
    margin: 0;
    text-decoration: none;
  }
  .menu-item-active {
    position: relative;
    color: #DBDBDB;
    height: 100%;
    font-size: 18px;
    line-height: 8vh;
    border-bottom: 0 solid #FFF;
    float: right;
    padding: 0 15px;
    text-align: center;
    cursor: pointer;
    margin: 0;
    text-decoration: none;
    font-weight: bold;
  }

  .menu-ul .active {
    background: #262626;
    color: #FFF;
    border-bottom: 5px solid #05ffa0;
  }

  .menu-ul .menu-item::before {
    content: "";
    position: absolute;
    top: 0;
    left: 100%;
    width: 0;
    height: 100%;
    border-bottom: 5px solid #05ffa0;
    transition: 0.1s all linear;
  }

  .menu-ul .menu-item:hover::before {
    width: 100%;
    left: 0;
  }

  .menu-ul .menu-item:hover ~ li::before {
    left: 0;
  }
}