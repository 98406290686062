@charset "UTF-8";

@import './base.scss';

$prefixCls: 'zby-notification';

.#{$prefixCls}-box {
  position: fixed;
  left: 50%;
  top: 50%;
  width: 300px;
  height: 100px;
  transform: translate3D(-50%, -50%, 0);
  z-index: 110;
}