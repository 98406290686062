.file-upload-container {
  width: 400px;
  margin: 20px auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.file-upload-title {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.file-upload-title h3 {
  margin-left: 10px;
  font-size: 20px;
  font-weight: bold;
  color: #333;
}

.file-upload-label {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  border-radius: 5px;
  cursor: pointer;
  padding: 10px;
  margin-bottom: 20px;
}

.file-upload-label:hover {
  background-color: #eee;
}

.file-upload-label svg {
  margin-right: 10px;
  font-size: 20px;
}

.file-upload-input {
  display: none;
}

.file-upload-drag-area {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed #ccc;
  border-radius: 5px;
  cursor: pointer;
  padding: 50px;
  transition: border-color 0.3s;
}

.file-upload-drag-area:hover {
  border-color: #0077cc;
}

.file-upload-drag-area svg {
  margin-right: 10px;
  font-size: 30px;
  transition: transform 0.3s;
}

.file-upload-drag-area:hover svg {
  transform: scale(1.2);
}


.selected-file {
  padding: 10px;
  background-color: #f5f5f5;
  margin-bottom: 20px;
  border-radius: 5px;
}

.selected-file p {
  margin: 0;
  padding: 5px 0;
  color: #333;
}

.file-upload-button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #0077cc;
  color: #fff;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 20px;
}

.file-upload-button:hover {
  background-color: #0062a1;
}

.file-upload-button:active {
  background-color: #005082;
}

.file-upload-container-large {
  width: 600px;
  margin: 20px auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.file-upload-container-large form {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.file-upload-drag-area {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed #ccc;
  border-radius: 5px;
  cursor: pointer;
  padding: 50px;
  transition: border-color 0.3s;
  flex: 1;
}



.file-upload-button:hover {
  background-color: #0062a1;
}

.file-upload-button:active {
  background-color: #005082;
}
.file-upload-name,
.file-upload-size {
  margin: 10px 0;
}

.file-upload-name {
  font-weight: bold;
}

.file-upload-success {
  margin-top: 20px;
  text-align: center;
  font-weight: bold;
  color: #5cb85c;
}

.file-info-list {
  list-style: none;
  padding: 0;
  margin: 10px 0 0;
}

.file-info-list li {
  margin-top: 5px;
}

//文件列表组件
.fileList-container {
  padding: 0 5px 5px 5px;
  border-radius: 5px;
  background-color: #f2f2f2;
}

.fileList-container h2{
  padding: 0 5px 10px 5px;
}

.fileList-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px;
  background-color: #FFF;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  position: relative;
  cursor: default;
}

.fileList-item:hover {
  background-color: #FFFFF2;
}
.fileList-item:hover .fileList-item-delete {
  opacity: 1;
}

.fileList-item-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.fileList-item-name {
  font-weight: 600;
  margin-bottom: 5px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.fileList-item-name:hover{
  color: #2f21d4;
}
.fileList-item-size {
  font-size: 14px;
}

.fileList-item-delete-container {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  transition: all 0.3s ease-in-out;
  z-index: 1;
}

.fileList-item-delete {
  padding: 5px;
  font-size: 12px;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  border: none;
  background: #ff4d4d;
  color: #000;
  cursor: pointer;
  border-radius: 5px;
}

.fileList-item-name > a, .fileList-item-delete:hover {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

.fileList-item-delete:hover {
  background-color: #ff4d4d;
  color: #fff;
}

@media (max-width: 768px) {
  .fileList-item-info {
    flex-direction: row;
    justify-content: space-between;
  }

  .fileList-item-name {
    margin-bottom: 0;
  }

  .fileList-item-delete-container {
    position: static;
    margin-left: 10px;
  }
}

.confirm-mask {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
}

.confirm-box {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 101;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.confirm-box p {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 20px;
}

.confirm-box button {
  background-color: #3F51B5;
  border: none;
  color: white;
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 15px;
  font-weight: bold;
  margin-right: 16px;
  cursor: pointer;
}

.confirm-box button:hover {
  background-color: #303F9F;
}

.confirm-box .cancel-btn {
  background-color: #F44336;
}

.confirm-box .cancel-btn:hover {
  background-color: #D32F2F;
}

.confirm-box .ok-btn {
  background-color: #4CAF50;
}

.confirm-box .ok-btn:hover {
  background-color: #388E3C;
}

