[data-component = Person] {
  /* General styles for all menus */
  #main-navigation {
    box-shadow: none;
  }

  .main-button {
    height: auto;
    position: fixed;
    width: auto;
    z-index: 999;
    top: 23px;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }

  .main-button.right {
    right: 40px;
  }

  .main-button.left {
    left: 15px;
  }

  .main-button.left > button.menu-active {
    left: 160px;
  }

  .main-button > button {
    background-color: #2C7EEA;
    padding-left: 10px;
    cursor: pointer;
    height: 36px;
    position: absolute;
    top: 8px;
    left: 0;
    width: 36px;
    z-index: 11;
    -webkit-border-radius: 2px;
    -ms--border-radius: 2px;
    border-radius: 2px;
    border: 1px solid rgba(245, 245, 245, 0.47);
  }

  .main-button > button.menu-active {
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }

  .main-button > button.menu-active span:first-child {
    transform: translateY(6px) rotate(-45deg);
  }

  .main-button > button.menu-active span:nth-child(2) {
    opacity: 0;
    transform: rotate(-45deg);
  }

  .main-button > button.menu-active span:last-child {
    transform: translateY(-6px) rotate(-135deg);
  }

  .main-button > button span {
    background: #fff none repeat scroll 0 0;
    display: block;
    height: 3px;
    pointer-events: none;
    transform-style: flat !important;
    -moz-transition: all 0.4s ease 0s;
    -webkit-transition: all 0.4s ease 0s;
    -ms-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    width: 14px;
  }

  .main-button > button span:nth-child(2) {
    margin: 3px 0;
  }

  .navbar-brand {
    display: inline-block;
  }

  nav {
    position: relative;
  }

  .cbp-spmenu {
    background: #fff;
    position: fixed;
    box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.176);
  }

  .push_nav_brand {
    margin: 30px 15px;
    display: inline-block;
    width: 110px;

  }

  .logo-space {
    padding: 32px 0;
  }

  .push_nav li {
    position: relative;
    overflow: hidden;
  }

  a.push_nav_brand {
    font-size: 28px;
    color: #fff;
    font-family: 'Raleway', sans-serif;
  }

  .push_nav li a,
  .push_nav li.active a {
    border-bottom: 1px solid rgba(245, 245, 245, 0.47);
    color: #fff;
    font-family: 'Raleway', sans-serif;
    font-size: 14px;
    display: block;
    padding: 15px;
    position: relative;
    text-transform: uppercase;
    -moz-transition: ease-out 0.3s 0.1s;
    -o-transition: ease-out 0.3s 0.1s;
    -webkit-transition: ease-out 0.3s;
    -webkit-transition-delay: 0.1s;
    transition: ease-out 0.3s 0.1s;
  }

  .push_nav li a::after,
  .push_nav li.active a::after,
  .push_nav li a:hover::after,
  .push_nav li.active a:hover::after,
  .push_nav li a:hover {
    background: #fff;
    color: #d63c5d;
  }

  .push_nav li a.active {
    background: #fff;
    color: #D63C5C;
  }

  ul.push_nav {
    padding: 0;
  }

  /* Orientation-dependent styles for the content of the menu */
  .cbp-spmenu-vertical {
    width: 240px;
    height: 100%;
    top: 0;
    z-index: 1000;
    overflow-y: auto;
  }

  .cbp-spmenu-vertical a {
  }

  .cbp-spmenu-horizontal {
    width: 100%;
    height: 150px;
    left: 0;
    z-index: 1000;
    overflow: hidden;
  }

  .cbp-spmenu-horizontal h3 {
    height: 100%;
    width: 20%;
    float: left;
  }

  .cbp-spmenu-horizontal a {
    float: left;
    width: 20%;
    padding: 0.8em;
    border-left: 1px solid #258ecd;
  }

  /* Vertical menu that slides from the left or right */
  .cbp-spmenu-left {
    left: -240px;
  }

  .cbp-spmenu-right {
    right: -240px;
  }

  .cbp-spmenu-left.menu-open {
    left: 0px;
    background: #333;
  }

  .cbp-spmenu-right.menu-open {
    right: 0px;
  }

  /* Horizontal menu that slides from the top or bottom */

  .cbp-spmenu-top {
    top: -150px;
  }

  .cbp-spmenu-bottom {
    bottom: -150px;
  }

  .cbp-spmenu-top.menu-open {
    top: 0px;
  }

  .cbp-spmenu-bottom.menu-open {
    bottom: 0px;
  }

  /* Push classes applied to the body */

  .push-body {
    overflow-x: hidden;
    position: relative;
    left: 0;
  }

  .push-body-toright {
    left: 240px;
  }

  .push-body-toleft {
    left: -240px;
  }

  /* Transitions */
  .cbp-spmenu,
  .push-body {
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }

  #main-navigation ul.icons {
    padding: 0;
    margin: 15px 2px;
    text-align: center;
    display: inline-block;
  }

  #main-navigation ul.icons li {
    float: left;
  }

  #main-navigation ul.icons li a.fa {
    display: inline-block;
    width: 32px;
    height: 32px;
    line-height: 32px;
    color: #fff;
    border: 1px solid #fff;
    border-radius: 100%;
    margin: 0px 0px 0 12px;
    font-size: 14px;

  }

  #main-navigation .icons a.fa:hover {
    color: #d63c5d;
    background: #fff;
    border: solid 1px #fff;
    overflow: hidden;
    -webkit-transition-duration: 0.8s;
    transition-duration: 0.8s;
    -webkit-transition-property: color, background-color;
    transition-property: color, background-color;
  }

  /* Example media queries */
  @media screen and (max-width: 767px) {
    #main-navigation {
      background-color: transparent;
    }
  }
  @media screen and (max-width: 55.1875em) {
    .cbp-spmenu-horizontal {
      font-size: 75%;
      height: 110px;
    }

    .cbp-spmenu-top {
      top: -110px;
    }

    .cbp-spmenu-bottom {
      bottom: -110px;
    }
  }

  @media screen and (max-height: 26.375em) {
    .cbp-spmenu-vertical {
      font-size: 90%;
      width: 190px;
    }

    .cbp-spmenu-left,
    .push-body-toleft {
      left: -190px;
    }

    .cbp-spmenu-right {
      right: -190px;
    }

    .push-body-toright {
      left: 190px;
    }
  }
}