[data-component = Editor] {
    #menu_box {
        position: relative;
        overflow-y: scroll;
        width: 20vw;
        min-width: 300px;
        height: 92vh;
        padding-right: 8px;
    }

    /*自定义滚动条*/
    #menu_box::-webkit-scrollbar {
        width: 8px;
        background-color: #eee;
    }

    #menu_box::-webkit-scrollbar-track {
        background-color: #eee;
    }

    #menu_box::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: #3DB6A4;
    }

    /*--------------------左侧菜单------------------*/
    .nav {
        width: 300px;
        background: #263238;
        transition: all .3s;
        position: absolute;
        top: 0;
        left: 0;
    }

    .nav a {
        display: block;
        overflow: hidden;
        padding-left: 15px;
        line-height: 46px;
        max-height: 46px;
        color: #ABB1B7;
        transition: all .3s;
    }

    .nav a span {
        margin-left: 25px;
    }

    .nav-item {
        position: relative;
        transition: all .3s;
    }

    .nav-item.nav-show {
        border-bottom: none;
    }

    .nav-item ul {
        display: none;
        background: rgba(0, 0, 0, .1);
    }

    .nav-item.nav-show ul {
        display: block;
    }

    .nav-item > a:before {
        content: "";
        position: absolute;
        left: 0;
        width: 2px;
        height: 46px;
        background: #34A0CE;
        opacity: 0;
        transition: all .3s;
    }

    .nav .nav-icon {
        font-size: 20px;
        position: absolute;
        margin-left: -1px;
    }

    @font-face {
        font-family: "iconfont";
        src: url('../../static/fonts/iconfont.ttf?t=1638459588296') format('truetype');
    }

    .my-icon {
        font-family: "iconfont", sans-serif !important;
        font-size: 16px;
        font-style: normal;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    .icon_1::after {
        content: "\e62b";
    }

    .icon_2::after {
        content: "\e669";
    }

    .icon_3::after {
        content: "\e61d";
    }

    /*---------------------*/
    .nav-more {
        float: right;
        margin-right: 20px;
        font-size: 15px;
        transition: transform .3s;
        font-weight: 700;
    }


    .nav-more::after {
        //content: "\f0343";
        content: ">";
    }

    /*---------------------*/
    .nav-show .nav-more {
        transform: rotate(90deg);
    }

    .nav-show, .nav-item > a:hover {
        color: #FFF;
        background: rgba(0, 0, 0, .1);
    }

    .nav-show > a:before, .nav-item > a:hover:before {
        opacity: 1;
    }

    .nav-item li:hover a {
        color: #FFF;
        background: rgba(0, 0, 0, .1);
    }

    /* nav-mini */
    .nav-mini.nav {
        width: 60px;
    }

    .nav-mini.nav .nav-icon { /* margin-left:-2px; */
    }

    .nav-mini.nav .nav-item > a span {
        display: none;
    }

    .nav-mini.nav .nav-more {
        margin-right: -20px;
    }

    .nav-mini.nav .nav-item ul {
        position: absolute;
        top: 0px;
        left: 60px;
        width: 180px;
        z-index: 99;
        background: #3C474C;
        overflow: hidden;
    }

    .nav-mini.nav .nav-item:hover {
        background: rgba(255, 255, 255, .1);
    }

    .nav-mini.nav .nav-item:hover .nav-item a {
        color: #FFF;
    }

    .nav-mini.nav .nav-item:hover a:before {
        opacity: 1;
    }

    .nav-mini.nav .nav-item:hover ul {
        display: block;
    }
    .blog_title{
        font-size: 14px;
    }
    .blog_type_li {
        font-size: 16px;
    }
}