@charset "UTF-8";

@import './base.scss';

$prefixCls: 'zby-notice';

// Notice
.#{$prefixCls} {
  position: absolute;
  box-sizing: border-box;
  left: 50%;
  top: 50%;
  margin: 0;
  width: 100%;
  padding: 10px 5px;
  background: rgba(45, 43, 43, 0.7);
  border-radius: 10px;
  text-align: center;
  font-size: 15px;
  line-height: 32px;
  color: #fff;
  z-index: 1110;
  transition: all .3s;
  transform: translate3D(-50%, -50%, 0) scale(1, 1);

  &.leave {
    transform: translate3D(-50%, -100%, 0);
    opacity: 0;
  }

  // 正常提示
  &.info {
    animation: Flop .5s linear;
  }

  // 成功提示
  &.success {
    animation: BombIn .3s;
  }

  // 警告提示⚠️
  &.warning {
    animation: slideInFromBottom .3s;
  }

  // 错误提示
  &.error {
    animation: shake .5s;
  }

  .#{$prefixCls}-icon {
    padding: 20px;
    font-size: 80px;
    text-align: center;
    color: #fff;
  }

  .#{$prefixCls}-content {
    font-size: 20px;
    text-align: center;
    color: #fff;
  }
}