[data-component = Content] {

    .nav-box {
        height: 50px;
        line-height: 50px;
        width: 100%;
        padding: 0 100px 0 50px;
        background: #555;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
    }

    .nav-box a:hover {
        color: #EEE;
    }

    .header-logo {
        left: 30px;
        width: 50px;
        height: 50px;
    }

    .header-logo img {
        width: 100%;
    }

    .menu-box {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
    }

    .menu-item {
        color: #FFF;
        font-weight: 700;
        margin: 0 1em;
    }


    .blog_show {
        width: 80%;
        margin: 0 auto;
    }

    #blog_body {

    }

    #blog_title {
        background: white;
        margin: 10px 0;
        border-bottom: none;
        display: flex;
        flex-direction: row;
    }

    #blog_title > .head_title {
        display: flex;
        flex-direction: column;
        flex: 1;
    }

    #blog_title > h3 {
        margin: 20px 0;
        font-size: 30px;
        font-weight: bold;
        font-family: 'Google Sans', 'Noto Sans Myanmar UI', arial, sans-serif;
    }

    .comment {
        height: auto;
        margin: 20px 0;
        background: white;
        padding: 10px;
        border: 1px solid #DBDBDB;
    }

    .comment h3 {
        margin-bottom: 10px;
    }

    .comment_item {
        margin: 20px 0;
        border-radius: 10px;
        box-shadow: 5px 5px 10px -2px #CDCDCD;
        display: flex;
        flex-direction: row;
    }

    .comment_box {
        display: flex;
        flex-direction: column;
        flex: 1;
    }

    #blog_title .head_img {
        display: inline-block;
        margin: 5px;
        width: 60px;
        height: 60px;
        border-radius: 100px;
        overflow: hidden;
    }

    #blog_title .content_title {
        display: block;
        font-size: 1.17em;
        font-weight: bold;
        font-family: inherit;
        color: inherit;
        margin: 10px 0;
    }

    .head_img {
        display: inline-block;
        margin: 15px 10px;
        width: 50px;
        height: 50px;
        border-radius: 100px;
        overflow: hidden;
    }

    .head_img img {
        width: 100%;
        min-height: 100%;
    }

    .nickname {
        display: block;
        height: 50px;
        line-height: 50px;
        font-size: 15px;
        color: #787d82;
        font-weight: 700;
    }

    #blog_tag > span {
        color: #000;
        margin: 10px 10px 10px 0;
    }

    #blog_tag em {
        color: #999;
    }

    #wrapper {
        text-align: center;
        padding: 50px 0;
    }

    #wrapper .line {
        width: 40%;
        display: inline-block;
        border: 0.4px solid #ddd;
        vertical-align: middle;
    }

    #wrapper .content {
        color: #ccc;
        font-size: 14px;
    }

    .form-control {
        width: 100%;
        height: 34px;
        padding: 6px 12px;
        font-size: 14px;
        color: #555;
        background-color: #fff;
        background-image: none;
        border: 1px solid #ccc;
        border-radius: 4px;
    }
}