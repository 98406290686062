[data-component = Person] {
    /*公共属性开始*/
    ::selection {
        color: #fff;
        background: #2C7EEA;
    }

    li {
        list-style: none;
    }

    ul, li {
        list-style: none;
    }

    .clear {
        clear: both;
    }

    *, *:after, *:before {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }

    /*Animated*/
    .animated {
        opacity: 1 !important;
    }

    .animate {
        opacity: 0;
    }

    /*公共属性结束*/

    .push-body {
        margin: 0;
        overflow-x: hidden;
        color: #fff;
        font-family: 'Raleway', sans-serif;
        webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        transition: all 0.3s ease;
    }

    /*manage Starts*/
    #main-navigation {
        position: fixed;
        top: 0;
        width: 100%;
        -webkit-transition: height 0.3s;
        -moz-transition: height 0.3s;
        -ms-transition: height 0.3s;
        -o-transition: height 0.3s;
        transition: height 0.3s;
        z-index: 999;
    }

    /*主页信息开始*/
    .info {
        margin: 12% 0 7% 0;
    }

    .info .info_detail {
        text-align: center;
        margin: 3% 0 0 0;
    }

    .info .info_detail h3 {
        color: #fff;
        font-size: 48px;
        line-height: 24px;
    }

    .info .info_detail p {
        color: #fff;
        font-size: 16px;
        line-height: 24px;
    }

    .info .info_detail img {
    }

    /*img zoom*/
    .info .info_detail .zoom {
        display: inline-block;
        position: relative;
        border-radius: 100%;
        border: 5px solid #fff;
        overflow: hidden;
        width: 200px;
        height: 200px;
        z-index: 999;
    }

    .info .info_detail .zoom img {
        width: 100%;
        height: 100%;
        border-radius: 100%;
        -moz-transition: all 0.3s;
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
    }

    .info .info_detail .zoom:hover img {
        -moz-transform: scale(1.3);
        -webkit-transform: scale(1.3);
        transform: scale(1.3);
        cursor: pointer;
    }

    .info .info_detail p {
        color: #fff;
    }

    .icons {
        margin: 15px 5px 15px 5px;
        text-align: center;
        display: none;
    }

    .icons ul {
        padding: 0;
    }

    .icons ul li {
        float: left;
    }

    .icons ul li button, a {
        padding: 0;
        margin: 0;
    }

    .icons ul li .fa {
        display: inline-block;
        width: 32px;
        height: 32px;
        line-height: 32px;
        color: #fff;
        border: 1px solid #b5b5b5;
        border-radius: 100%;
        margin: 0 5px 0;
        font-size: 14px;
        overflow: hidden;
        -webkit-transition-duration: 0.5s;
        transition-duration: 0.5s;
        -webkit-transition-property: color, background-color;
        transition-property: color, background-color;
    }

    .icons button {
        background: none;
    }

    .icons .fa.fb:hover {
        color: #fff;
        background: #3b5999;
        border: solid 1px #3b5999;
    }

    .icons .fa.tw:hover {
        color: #fff;
        background: #55acef;
        border: solid 1px #55acef;
    }

    .icons .fa.gp:hover {
        color: #fff;
        background: #de4b39;
        border: solid 1px #de4b39;
    }

    .icons .fa.in:hover {
        color: #fff;
        background: #156ea2;
        border: solid 1px #156ea2;
    }

    .icons .fa.pin:hover {
        color: #fff;
        background: #cf2833;
        border: solid 1px #cf2833;
    }

    .icons img {
        width: 100%;
        height: 100%;
        padding: 4px;
    }


    .button {
        text-align: center;
        margin: 10% 0 0 0;
    }

    .button a.btn {
        display: inline-block;
        padding: 12px 42px;
        margin: 0 15px;
        border: solid 2px #fff;
        border-radius: 3px;
        color: #fff;
        font-weight: 400;
        text-align: center;
        white-space: nowrap;
        vertical-align: middle;
        -ms-touch-action: manipulation;
        touch-action: manipulation;
        cursor: pointer;
        overflow: hidden;
        -webkit-transition-duration: 0.5s;
        transition-duration: 0.5s;
        -webkit-transition-property: color, background-color;
        transition-property: color, background-color;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        box-shadow: none;
    }

    .button a.btn:hover {
        color: #d63c5d;
        background: #fff;
        border: solid 2px #fff;
    }

    .button a.btn.active:hover {
        color: #d63c5d;
        background: #fff;
        border: solid 2px #fff;
        overflow: hidden;
    }

    .button a.btn.active {
        background: #d63c5d;
        border: 2px solid #d63c5d;
        color: #fff;
    }

    /*Info结束*/

    .career {
        margin: 5% 0;
    }

    .career .heading {
    }

    .career .heading h1 {
        font-size: 30px;
        line-height: 29px;
    }

    .career .heading p {
        width: 46%;
        text-align: center;
        display: inline-block;
    }

    .career .item {
    }

    .career .item h2 {
        font-family: 'Raleway', sans-serif;
        font-size: 20px;
        line-height: 20px;
        color: #d63c5d;
        margin: 6% 0 1% 0;
    }

    .career .item em {
        font-family: 'Open Sans', sans-serif;
        display: block;
        font-style: italic;
        font-size: 15px;
        line-height: 10px;
        margin-bottom: 2%;
    }

    .career .item span {
        font-size: 14px;
        font-family: 'Open Sans', sans-serif;
        font-style: normal;
    }

    .career .item p {
        font-size: 14px;
    }

    .career .skills {
    }

    .career .skills .heading {
    }

    .career .skills .heading h1 {
        font-size: 30px;
        line-height: 29px;
    }

    .career .skills .heading p {
        margin-bottom: 50px;
        width: 48%;
        text-align: center;
        display: inline-block;
    }

    .career .skills .circle {
        width: 100%;
        float: left;
    }

    .career .skills .circle .circle_detail {
        width: 23%;
        display: inline-block;
        float: left;
        margin: 0 18px 0 0;
    }

    .career .skills .circle .circle_detail h3 {
        font-size: 15px;
        line-height: 20px;
        text-align: center;
    }

    .circle_detail:hover h3, .circle_detail.active h3 {
        color: #d63c5d !important;
    }

    .career .skills .circle .circle_detail p {
        font-size: 13px;
        line-height: 20px;
        text-align: center;
    }

    canvas {
        height: 130px !important;
        width: 130px !important;
    }

    .myStat {
        display: inline-block;
        background: #2f293c;
        height: 130px;
        width: 130px !important;
        border-radius: 100%;
        font-family: 'Open Sans', sans-serif;
    }

    .circle_detail:hover .myStat, .circle_detail.active .myStat {
        background: #d63c5d;
        cursor: pointer;
        overflow: hidden;
        -webkit-transition-duration: 0.5s;
        transition-duration: 0.5s;
        -webkit-transition-property: color, background-color;
        transition-property: color, background-color;
    }


    /*联系方式*/
    .contact {

    }

    .contact .heading {
    }

    .contact .heading h1 {
        color: #fff;
        font-size: 30px;
        line-height: 29px;
        margin-bottom: 18px;
    }

    .contact .heading p {
        font-size: 13px;
        line-height: 20px;
        color: #fff;
        margin-bottom: 10px;
        width: 55%;
        text-align: center;
        display: inline-block;
    }

    :focus {
        outline: none;
    }

    .contact .contact_info {
        text-align: left;
    }

    .contact .contact_info h3 {
        font-size: 20px;
        line-height: 30px;
        color: #d0cfd2;
        border-bottom: solid 1px #89878f;
        margin: 0 0 0 0;
    }

    .contact .contact_info .contact_sec {
        color: #d0cfd2;
        padding: 15px 0 20px 0;
    }

    .contact .contact_info .contact_sec ul {
        margin: 0;
        padding: 0;
    }

    .contact .contact_info .contact_sec ul li {
        font-size: 14px;
        line-height: 26px;
    }

    .contact .contact_info .contact_sec ul li i.fa {
        padding: 12px 35px 0 0;
    }

    .contact .contact_info .contact_sec ul li span {
    }

    .contact .contact_info .contact_sec ul li span a {
        color: #d0cfd2;
        text-decoration: none;
    }

    .contact .contact_info .contact_icons {
        text-align: center;
        display: inline-block;
    }

    .contact .contact_info .contact_icons ul {
        margin: 0;
        padding: 0;
    }

    .contact .contact_info .contact_icons ul li {
        float: left;
    }

    .contact .contact_info .contact_icons ul li a.fa {
        display: inline-block;
        width: 32px;
        height: 32px;
        line-height: 32px;
        color: #c1bfc5;
        border: 1px solid #b5b5b5;
        border-radius: 100%;
        margin: 0 12px 0 0;
        font-size: 14px;
        overflow: hidden;
        -webkit-transition-duration: 0.5s;
        transition-duration: 0.5s;
        -webkit-transition-property: color, background-color;
        transition-property: color, background-color;
    }

    .contact .contact_info .contact_icons a.fa.fb:hover {
        color: #fff;
        background: #3b5999;
        border: solid 1px #3b5999;
    }

    .contact .contact_info .contact_icons a.fa.tw:hover {
        color: #fff;
        background: #55acef;
        border: solid 1px #55acef;
    }

    .contact .contact_info .contact_icons a.fa.gp:hover {
        color: #fff;
        background: #de4b39;
        border: solid 1px #de4b39;
    }

    .contact .contact_info .contact_icons a.fa.in:hover {
        color: #fff;
        background: #156ea2;
        border: solid 1px #156ea2;
    }

    .contact .contact_info .contact_icons a.fa.pin:hover {
        color: #fff;
        background: #cf2833;
        border: solid 1px #cf2833;
    }


    /*页尾*/
    .footer {
        margin: 13% 0 0 0;
    }

    .footer p {
        color: #acabb0;
        font-size: 13px;
        line-height: 20px;
    }

    /*===== sections ======*/
    .section {
        min-height: 100%;
        position: relative;
        -webkit-transition: all .8s ease-in-out;
        -moz-transition: all .8s ease-in-out;
        -o-transition: all .8s ease-in-out;
        transition: all .8s ease-in-out;
        padding: 50px 0;
    }

    .col-md-9 {
        width: 76%;
    }

    .col-xs-6 {
        width: 100%;
    }

    #home {
        background: url("https://zlm-image.oss-cn-beijing.aliyuncs.com/page-img/bg-mia.jpg") no-repeat center center fixed;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        font-family: 'Raleway', sans-serif;
    }

    #aboutme {
        width: 100%;
        height: auto;
        background: #ededed;
        font-family: 'Raleway', sans-serif;
    }

    #resume {
        width: 100%;
        height: 155em;
        background: #EEE;
        font-family: 'Raleway', sans-serif;
    }

    #contactme {
        background: url("https://zlm-image.oss-cn-beijing.aliyuncs.com/page-img/bg-contact.jpg") no-repeat center center fixed;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        font-family: 'Raleway', sans-serif;
    }

    /*Index2*/
    #homesec {
        background: #2f293c;
        font-family: 'Raleway', sans-serif;
    }

    #contactmesec {
        background: #2f293c;
        font-family: 'Raleway', sans-serif;
    }

    /* circle ful */
    .circliful {
        position: relative;
    }

    .circle-text {
        width: 100%;
        line-height: 130px !important;
        position: absolute;
        color: #fff;
        text-align: center;
        display: inline-block;
    }

    .circliful .fa {
        margin: -10px 3px 0 3px;
        position: relative;
        bottom: 4px;
    }

    .text-center {
        text-align: center;
    }

    .fileList :nth-child(2) {
        top: 80em;

    }

    /*公共属性开始*/
    ::selection {
        color: #fff;
        background: #2C7EEA;
    }

    .clear {
        clear: both;
    }

    *, *:after, *:before {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }

    /*Animated*/
    .animated {
        opacity: 1 !important;
    }

    .animate {
        opacity: 0;
    }

    /*公共属性结束*/

    .push-body {
        margin: 0;
        overflow-x: hidden;
        color: #fff;
        font-family: 'Raleway', sans-serif;
        webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        transition: all 0.3s ease;
    }

    /*manage Starts*/
    #main-navigation {
        position: fixed;
        top: 0;
        width: 100%;
        -webkit-transition: height 0.3s;
        -moz-transition: height 0.3s;
        -ms-transition: height 0.3s;
        -o-transition: height 0.3s;
        transition: height 0.3s;
        z-index: 999;
    }

    /*主页信息开始*/
    .info {
        margin: 12% 0 7% 0;
    }

    .info .info_detail {
        text-align: center;
        margin: 3% 0 0 0;
    }

    .info .info_detail h3 {
        color: #fff;
        font-size: 48px;
        line-height: 24px;
    }

    .info .info_detail p {
        color: #fff;
        font-size: 16px;
        line-height: 24px;
    }

    .info .info_detail img {
    }

    /*img zoom*/
    .info .info_detail .zoom {
        display: inline-block;
        position: relative;
        border-radius: 100%;
        border: 5px solid #fff;
        overflow: hidden;
        width: 200px;
        height: 200px;
        z-index: 999;
    }

    .info .info_detail .zoom img {
        width: 100%;
        height: 100%;
        border-radius: 100%;
        -moz-transition: all 0.3s;
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
    }

    .info .info_detail .zoom:hover img {
        -moz-transform: scale(1.3);
        -webkit-transform: scale(1.3);
        transform: scale(1.3);
        cursor: pointer;
    }

    .info .info_detail p {
        color: #fff;
    }

    .icons {
        margin: 15px 5px 15px 5px;
        text-align: center;
        display: none;
    }

    .icons ul {
        padding: 0;
    }

    .icons ul li {
        float: left;
    }

    .icons ul li button, a {
        padding: 0;
        margin: 0;
    }

    .icons ul li .fa {
        display: inline-block;
        width: 32px;
        height: 32px;
        line-height: 32px;
        color: #fff;
        border: 1px solid #b5b5b5;
        border-radius: 100%;
        margin: 0 5px 0;
        font-size: 14px;
        overflow: hidden;
        -webkit-transition-duration: 0.5s;
        transition-duration: 0.5s;
        -webkit-transition-property: color, background-color;
        transition-property: color, background-color;
    }

    .icons button {
        background: none;
    }

    .icons .fa.fb:hover {
        color: #fff;
        background: #3b5999;
        border: solid 1px #3b5999;
    }

    .icons .fa.tw:hover {
        color: #fff;
        background: #55acef;
        border: solid 1px #55acef;
    }

    .icons .fa.gp:hover {
        color: #fff;
        background: #de4b39;
        border: solid 1px #de4b39;
    }

    .icons .fa.in:hover {
        color: #fff;
        background: #156ea2;
        border: solid 1px #156ea2;
    }

    .icons .fa.pin:hover {
        color: #fff;
        background: #cf2833;
        border: solid 1px #cf2833;
    }

    .icons img {
        width: 100%;
        height: 100%;
        padding: 4px;
    }


    .button {
        text-align: center;
        margin: 10% 0 0 0;
    }

    .button a.btn {
        display: inline-block;
        padding: 12px 42px;
        margin: 0 15px;
        border: solid 2px #fff;
        border-radius: 3px;
        color: #fff;
        font-weight: 400;
        text-align: center;
        white-space: nowrap;
        vertical-align: middle;
        -ms-touch-action: manipulation;
        touch-action: manipulation;
        cursor: pointer;
        overflow: hidden;
        -webkit-transition-duration: 0.5s;
        transition-duration: 0.5s;
        -webkit-transition-property: color, background-color;
        transition-property: color, background-color;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        box-shadow: none;
    }

    .button a.btn:hover {
        color: #d63c5d;
        background: #fff;
        border: solid 2px #fff;
    }

    .button a.btn.active:hover {
        color: #d63c5d;
        background: #fff;
        border: solid 2px #fff;
        overflow: hidden;
    }

    .button a.btn.active {
        background: #d63c5d;
        border: 2px solid #d63c5d;
        color: #fff;
    }

    /*Info结束*/

    .career {
        margin: 5% 0;
    }

    .career .heading {
    }

    .career .heading h1 {
        font-size: 30px;
        line-height: 29px;
    }

    .career .heading p {
        width: 46%;
        text-align: center;
        display: inline-block;
    }

    .career .item {
    }

    .career .item h2 {
        font-family: 'Raleway', sans-serif;
        font-size: 20px;
        line-height: 20px;
        color: #d63c5d;
        margin: 6% 0 1% 0;
    }

    .career .item em {
        font-family: 'Open Sans', sans-serif;
        display: block;
        font-style: italic;
        font-size: 15px;
        line-height: 10px;
        margin-bottom: 2%;
    }

    .career .item span {
        font-size: 14px;
        font-family: 'Open Sans', sans-serif;
        font-style: normal;
    }

    .career .item p {
        font-size: 14px;
    }

    .career .skills {
    }

    .career .skills .heading {
    }

    .career .skills .heading h1 {
        font-size: 30px;
        line-height: 29px;
    }

    .career .skills .heading p {
        margin-bottom: 50px;
        width: 48%;
        text-align: center;
        display: inline-block;
    }

    .career .skills .circle {
        width: 100%;
        float: left;
    }

    .career .skills .circle .circle_detail {
        width: 23%;
        display: inline-block;
        float: left;
        margin: 0 18px 0 0;
    }

    .career .skills .circle .circle_detail h3 {
        font-size: 15px;
        line-height: 20px;
        text-align: center;
    }

    .circle_detail:hover h3, .circle_detail.active h3 {
        color: #d63c5d !important;
    }

    .career .skills .circle .circle_detail p {
        font-size: 13px;
        line-height: 20px;
        text-align: center;
    }

    canvas {
        height: 130px !important;
        width: 130px !important;
    }

    .myStat {
        display: inline-block;
        background: #2f293c;
        height: 130px;
        width: 130px !important;
        border-radius: 100%;
        font-family: 'Open Sans', sans-serif;
    }

    .circle_detail:hover .myStat, .circle_detail.active .myStat {
        background: #d63c5d;
        cursor: pointer;
        overflow: hidden;
        -webkit-transition-duration: 0.5s;
        transition-duration: 0.5s;
        -webkit-transition-property: color, background-color;
        transition-property: color, background-color;
    }


    /*联系方式*/
    .contact {

    }

    .contact .heading {
    }

    .contact .heading h1 {
        color: #fff;
        font-size: 30px;
        line-height: 29px;
        margin-bottom: 18px;
    }

    .contact .heading p {
        font-size: 13px;
        line-height: 20px;
        color: #fff;
        margin-bottom: 10px;
        width: 55%;
        text-align: center;
        display: inline-block;
    }

    :focus {
        outline: none;
    }

    .contact .contact_info {
        text-align: left;
    }

    .contact .contact_info h3 {
        font-size: 20px;
        line-height: 30px;
        color: #d0cfd2;
        border-bottom: solid 1px #89878f;
        margin: 0 0 0 0;
    }

    .contact .contact_info .contact_sec {
        color: #d0cfd2;
        padding: 15px 0 20px 0;
    }

    .contact .contact_info .contact_sec ul {
        margin: 0;
        padding: 0;
    }

    .contact .contact_info .contact_sec ul li {
        font-size: 14px;
        line-height: 26px;
    }

    .contact .contact_info .contact_sec ul li i.fa {
        padding: 12px 35px 0 0;
    }

    .contact .contact_info .contact_sec ul li span {
    }

    .contact .contact_info .contact_sec ul li span a {
        color: #d0cfd2;
        text-decoration: none;
    }

    .contact .contact_info .contact_icons {
        text-align: center;
        display: inline-block;
    }

    .contact .contact_info .contact_icons ul {
        margin: 0;
        padding: 0;
    }

    .contact .contact_info .contact_icons ul li {
        float: left;
    }

    .contact .contact_info .contact_icons ul li a.fa {
        display: inline-block;
        width: 32px;
        height: 32px;
        line-height: 32px;
        color: #c1bfc5;
        border: 1px solid #b5b5b5;
        border-radius: 100%;
        margin: 0 12px 0 0;
        font-size: 14px;
        overflow: hidden;
        -webkit-transition-duration: 0.5s;
        transition-duration: 0.5s;
        -webkit-transition-property: color, background-color;
        transition-property: color, background-color;
    }

    .contact .contact_info .contact_icons a.fa.fb:hover {
        color: #fff;
        background: #3b5999;
        border: solid 1px #3b5999;
    }

    .contact .contact_info .contact_icons a.fa.tw:hover {
        color: #fff;
        background: #55acef;
        border: solid 1px #55acef;
    }

    .contact .contact_info .contact_icons a.fa.gp:hover {
        color: #fff;
        background: #de4b39;
        border: solid 1px #de4b39;
    }

    .contact .contact_info .contact_icons a.fa.in:hover {
        color: #fff;
        background: #156ea2;
        border: solid 1px #156ea2;
    }

    .contact .contact_info .contact_icons a.fa.pin:hover {
        color: #fff;
        background: #cf2833;
        border: solid 1px #cf2833;
    }


    /*页尾*/
    .footer {
        margin: 13% 0 0 0;
    }

    .footer p {
        color: #acabb0;
        font-size: 13px;
        line-height: 20px;
    }

    /*===== sections ======*/
    .section {
        min-height: 100%;
        position: relative;
        -webkit-transition: all .8s ease-in-out;
        -moz-transition: all .8s ease-in-out;
        -o-transition: all .8s ease-in-out;
        transition: all .8s ease-in-out;
        padding: 50px 0;
    }

    .col-md-9 {
        width: 76%;
    }

    .col-xs-6 {
        width: 100%;
    }

    #home {
        background: url("https://zlm-image.oss-cn-beijing.aliyuncs.com/page-img/bg-mia.jpg") no-repeat center center fixed;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        font-family: 'Raleway', sans-serif;
    }

    #aboutme {
        width: 100%;
        height: auto;
        background: #ededed;
        font-family: 'Raleway', sans-serif;
    }

    #resume {
        width: 100%;
        height: 155em;
        background: #EEE;
        font-family: 'Raleway', sans-serif;
    }

    #contactme {
        background: url("https://zlm-image.oss-cn-beijing.aliyuncs.com/page-img/bg-contact.jpg") no-repeat center center fixed;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        font-family: 'Raleway', sans-serif;
    }

    /*Index2*/
    #homesec {
        background: #2f293c;
        font-family: 'Raleway', sans-serif;
    }

    #contactmesec {
        background: #2f293c;
        font-family: 'Raleway', sans-serif;
    }

    /* circle ful */
    .circliful {
        position: relative;
    }

    .circle-text {
        width: 100%;
        line-height: 130px !important;
        position: absolute;
        color: #fff;
        text-align: center;
        display: inline-block;
    }

    .circliful .fa {
        margin: -10px 3px 0 3px;
        position: relative;
        bottom: 4px;
    }

    .text-center {
        text-align: center;
    }

    .fileList :nth-child(2) {
        top: 80em;

    }
}