[data-component = Blog] {
    

    /*博客页头像跳动效果*/
    .head_center {
        text-align: left;
        height: 60px;
    }

    .head_center .head_img {
        display: inline-block;
        margin: 5px 10px;
        width: 50px;
        height: 50px;
        border-radius: 50px;
        -webkit-border-radius: 50px;
        -moz-border-radius: 50px;
        border: 2px solid #fff;
        box-shadow: 0 0 4px #ccc;
        overflow: hidden;
    }

    .head_center .head_img img {
        width: 100%;
        min-height: 100%;
    }

    .userName {
        color: #FFF;
        font-weight: bold;
        font-size: 20px;
    }

    #blog {
        position: static;
        background-size: cover;
    }


    #blog_top {
        position: fixed;
        background: white;
        width: 100vw;
        height: 60px;
        z-index: 2;
    }

    .blog-list-header {
        width: 100vw;
        padding: 0 20% 0;
        height: 60px;
        font-size: .85em;
        border-bottom: 1px solid #EFEFEF;
    }

    #blog_list_box {
        padding: 60px 0;
        width: 100vw;
        min-height: 80vh;
        background: #FFF;
        overflow-y: auto;
        transition: all 1s;
    }



    .sidebar-header{
        text-align: center;
        width: 100%;
        height: 50px;
    }

    .my_blog {
        margin: 5px auto;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-items: center;
        width: 250px;
    }

    .my_blog a {
        color: #4a4a4a;
        text-decoration: none;
        font-family: HeadFont, sans-serif;
        font-size: 1.5em;
        font-weight: 700;
        line-height: 1.5;
    }

    .my_blog a:hover {
        color: #ff4d5a;
        text-decoration: none;
    }

    .my_blog a:focus {
        color: #333;
        text-decoration: none
    }

    .my_blog > div {
        width: 80px;
        text-align: center;
        flex: 1;
    }

    .un-link {
        height: 1px;
        transition: 0.5s;
        width: 70%;
        margin: 0 auto;
        border: 1px dotted #d0d6dd;
    }

    .my_blog > div:hover .un-link {
        width: 70%;
        height: 4px;
        border: none;
        background: #1B9AF7;
    }


    /*博客类型导航栏*/
    .sidebar {
        display: none;
        text-align: center;
        width: 100%;
        margin: 0 auto;
        border-bottom: 1px solid #EFEFEF;
        overflow-x: hidden;
    }
    .sidebar-ul{
        width: 50%;
        min-height: 50px;
        margin: 0 auto;
    }

    .sidebar-ul li:hover {
        border: 1px solid #2e2929;
    }

    .sidebar-ul li {
        list-style: none;
        display: inline-block;
        float: left;
        height: 35px;
        line-height: 20px;
        width: auto;
        color: black;
        cursor: pointer;
        text-align: center;
        border: 1px solid #DBDBDB;
        border-radius: 10px;
        margin: 7px;
        padding: 5px;
    }

    .tag_active {
        background: #EEEEEE;
    }
    /*博客类型导航栏*/




    .blog_editor {
        font-family: sans-serif, Helvetica, SimSun, LiSu;
        font-size: 22px;
        position: fixed;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-items: center;
        width: 110px;
        right: 10px;
        line-height: 45px;
    }

    .blog_editor a {
        color: white;
        text-decoration: none;
    }

    .blog_editor a:hover {
        color: #DBDBDB;
        text-decoration: none;
    }

    .blog_editor a:focus {
        color: white;
        text-decoration: none
    }

    .blog_editor > div {
        width: 80px;
        text-align: center;
        flex: 1;
    }

    #full_bnt {
        height: 48px;
        width: 48px;
    }

    #full_bnt > img {
        cursor: pointer;
        margin: 10px;
        width: 30px;
        transition: 0.5s;
    }

    .blog_page{
        background: #EEE;
        overflow: hidden;
        min-height: 700px;
        height: auto;
    }

    .blog_box {
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: start;
    }

    .blog_list {
        width: 320px;
        height: 380px;
        margin: 10px;
        cursor: pointer;
        border: 1px solid #e5e7eb;
        border-radius: 10px;
        background: white;
        box-sizing: border-box;
        box-shadow: 0 0 4px #ccc;
        overflow: hidden;
    }

    .blog_list:hover {
        /*box-shadow: 5px 5px 10px -2px #888888;*/
        /*animation: myfirst 0.5s infinite;*/
        transition: 0.2s;
        /*animation-iteration-count:1;*/
        cursor: pointer;
    }

    .blog_time {
        color: blueviolet;
        font-size: 15px;
    }
    .blog_img{
        width: 320px;
        height: 270px;
    }
    .blog_img img{
        width: 320px;
        height: 270px;
    }
    .blog_title {
        height: 50px;
        color: #08a8f5;
        text-decoration: none;
        margin: 20px 20px 0;
        font-size: 1rem;
        font-weight: 600;
        line-height: 25px;
        font-family: sans-serif;
    }

    .blog_title::before {

    }

    .blog_preview {
        padding-left: 8px;
        height: auto;
        margin: 5px 0;
    }

    .blog_preview p {
        color: #666;
        font-size: 15px;
        font-weight: 400;
        text-indent: 40px;
    }

    .blog_title:hover {
        color: #6C95F5;
    }

    .blog_tip_box {
        height: 30px;
        display: flex;
        justify-content: space-between;
    }

    .blog_tip {
        font-size: 15px;
        font-weight: 400;
        color: #5e5e5e;
        margin: 0 20px;
        display: inline-block;
        width: auto;
        padding: .4em 0;
    }

    .blog_content {
        font-size: 1.6em;
        width: 80%;
        margin: 0 auto;
        max-width: 1300px;

        color: #565c63;
        line-height: 1.8;
        position: relative;
        padding: .4em 0 0;
        font-weight: 400;
    }

    #blog_head {
        height: 45px;
        background: #555555;
    }

    .page_a {
        cursor: pointer;
    }

    .blog_bottom {
        font-size: 12px;
        font-family: "Helvetica Neue", Helvetica, "Trebuchet MS", Arial, sans-serif;
    }

    .blog_bottom span {
        margin-right: 10px;
    }



    .btn_all_content {
        display: inline-block;
        width: 150px;
        border: 2px solid #787d82;
        margin: 2px;
        padding: 4px 0;
        border-radius: 10px;
        box-shadow: 0 0 4px #ccc;
        overflow: hidden;
    }

    .btn_all_content a:hover {
        color: #6C95F5;
    }

    .btn_all_content a {
        font-size: 1.25em;
        font-weight: 700;
        font-family: inherit;
        color: #000000;
    }

    .page-box {
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}