body {
  width: 100vw;
  height: 100vh;
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*  模态框样式 */
#loading {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: black;
  opacity: 0.4;
  margin: 0 auto;
  z-index: 1000;
}
/* 动图样式 */
#loading .gif {
  height: 6vw;
  width: 6vw;
  left: 47vw;
  right: 47vw;
  top: 65vh;
  position: fixed;
  z-index: 1001;
}
.gif img{
  width: 100%;
  height: 100%;
}

html {
  overflow-y: scroll; //这是为了兼容ie8，不支持:root, vw
}

:root {
  overflow-y: auto;
  overflow-x: hidden;
}

:root body {
  z-index: 3;
  position: absolute;
}


body::-webkit-scrollbar {
  width: 8px;
  background-color: #eee;
}

body::-webkit-scrollbar-track {
  background-color: #eee;
}

body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #3DB6A4;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

*{
  padding: 0;
  margin: 0;
}

h1,h2,h3,h4{
  font-family:'hanzo',sans-serif;
}

*, *:after, *:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

a{
  text-decoration: none;
}

/*back-to-top*/
.cd-top {
  display: inline-block;
  height: 40px;
  width: 40px;
  position: fixed;
  bottom: 25px;
  right: 10px;
  z-index: 10;
  border-radius: 3px;
  /* image replacement properties */
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
  /*background: #d63c5d url( '/img/cd-top-arrow.svg') no-repeat center 50%;*/
  visibility: hidden;
  opacity: 0;
  -webkit-transition: opacity .3s 0s, visibility 0s .3s;
  -moz-transition: opacity .3s 0s, visibility 0s .3s;
  transition: opacity .3s 0s, visibility 0s .3s;
}
.cd-top.cd-is-visible, .cd-top.cd-fade-out, .no-touch .cd-top:hover {
  -webkit-transition: opacity .3s 0s, visibility 0s 0s;
  -moz-transition: opacity .3s 0s, visibility 0s 0s;
  transition: opacity .3s 0s, visibility 0s 0s;
}
.cd-top.cd-is-visible {
  /* the button becomes visible */
  visibility: visible;
  opacity: 1;
}
.cd-top.cd-fade-out {
  /* if the user keeps scrolling down, the button is out of focus and becomes less visible */
  opacity: .5;
}
.cd-top:hover {
  background-color: #d63c5d;
  opacity: 1;
}

@font-face {
  font-family:"HeadFont";
  src:url('static/fonts/华文行楷.woff') format("woff");
}
