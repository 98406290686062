[data-component = Editor] {
  /*整体布局*/
  #main_box{
    width: 100vw;
    height: 92vh;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  .menu_body {
    width: 20vw;
    min-width: 300px;
    height: 92vh;
  }

  #markdown {
    position: relative;
    width: 80vw;
    min-width: 800px;
    overflow-x: hidden;
    height: 92vh;
  }

  /*自定义滚动条*/
  #markdown::-webkit-scrollbar {
    width: 8px;
    background-color: #eee;
  }

  #markdown::-webkit-scrollbar-track {
    background-color: #eee;
  }

  #markdown::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #3DB6A4;
  }

  .section-container::-webkit-scrollbar {
    width: 8px;
    background-color: #eee;
  }

  .section-container::-webkit-scrollbar-track {
    background-color: #eee;
  }

  .section-container::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #3DB6A4;
  }

  #add_type {
    line-height: 34px;
  }

  #select_type, #add_type {
    cursor: pointer;
  }

  #blog_editor {
    border-left: 1px solid #DBDBDB;
  }
  .input-container {
    display: flex;
    align-items: center;
  }

  label {
    font-size: 14px;
    margin-right: 10px;
  }

  input[type="text"] {
    border: none;
    border-bottom: 1px solid #ccc;
    font-size: 16px;
    padding: 5px 10px;
    transition: border-bottom-color 0.3s ease;
  }

  input[type="text"]:focus {
    border-bottom-color: #4CAF50;
    outline: none;
  }

  .editor-form{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 5px;
    border-bottom: 1px solid #ccc;
  }
  .editor-title{
    flex: 3;
  }
  .input-box {
    height: 40px;
    width: 600px;
    margin: 0 20px;
    border-radius: 50px;
    border: 2px solid #ccc;
    outline: none;
    transition: all 0.3s;
    overflow: hidden;
  }
  .title-input {
    display: inline-block;
    position: relative;
    font-size: 20px;
    height: 40px;
    width: 550px;
  }
  .title-input:focus {
    border-color: #4CAF50;
  }
  .title-input::placeholder {
    color: #ccc;
    font-size: 16px;
  }
  .title-input:focus::placeholder {
    color: #999;
  }
  .word-count {
    right: 15px;
    bottom: 10px;
    font-size: 14px;
    color: #999;
  }
  .editor-save{
    flex: 8;
  }

  .dropdown {
    position: relative;
    display: inline-block;
    height: 40px;
  }

  .dropdown-selected {
    height: 40px;
    width: 150px;
    display: flex;
    align-items: center;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    color: #565556;
    font-size: 14px;
  }

  .arrow {
    position: absolute;
    margin: 0 10px;
    width: 0;
    height: 0;
    right: 0;
    border-style: solid;
    border-width: 6px 5px 0 5px;
    border-color: #333 transparent transparent transparent;
  }

  .arrow.up {
    border-width: 0 5px 6px 5px;
    border-color: transparent transparent #333 transparent;
  }

  .dropdown-options {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 300px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    background-color: #fff;
    overflow-x: hidden;
  }

  .dropdown-options::-webkit-scrollbar {
    width: 4px;
    background-color: #eee;
  }

  .dropdown-options::-webkit-scrollbar-track {
    background-color: #eee;
  }

  .dropdown-options::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: #3DB6A4;
  }

  .dropdown-option {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    cursor: pointer;
  }

  .dropdown-option:hover {
    background-color: #f5f5f5;
  }

  .dropdown-option.selected {
    background-color: #f5f5f5;
    font-weight: bold;
  }

}