[data-component = Home] {
    section {
        margin: 0;
        padding: 0;
    }

    footer {
        margin: 0;
        padding: 20px;
    }

    em {
        font-size: 13px;
        color: blue;
    }

    img {
        vertical-align: center;
    }

    @keyframes myfirst {
        0% {
            transform: translate(0px, 0px);
        }
        50% {
            transform: translate(0px, -10px);
        }
        100% {
            transform: translate(0px, 0px);
        }
    }


    /*header开始*/
    #header {
        width: 100vw;
        height: 100vh;
        background: url('https://zlm-image.oss-cn-beijing.aliyuncs.com/page-img/bg.jpg') no-repeat center;
        background-size: cover;
    }

    .main .head_img {
        margin: 5px auto;
        width: 100px;
        height: 100px;
        border-radius: 50%;
        border: 2px solid #fff;
        box-shadow: 0 0 4px #ccc;
        overflow: hidden;
        animation: myfirst 2s infinite;
        cursor: pointer;
    }

    .main .head_img img:hover {
        /*transform: scale(1.2);*//*缩放*/
        transform: rotate(360deg);
        -webkit-transform: rotate(360deg); /*旋转*/
        /*transform: translateZ(100px);*/
    }

    .main .head_img img {
        width: 100%;
        min-height: 100%;
        transition: all 0.5s;
    }

    #head_text {
        margin: 5px auto;
        width: 100%;
        text-align: center;
        overflow: hidden;
        display: inline-block;
        font-size: 35px;
        font-family: HeadFont, sans-serif;
    }

    @-webkit-keyframes floatUp {
        to {
            width: auto;
            height: auto;
            transform: translate3d(0, 0, 0);
            -webkit-transform: translate3d(0, 0, 0);
        }
    }

    .main {
        top: 60px;
        width: 100%;
        padding: 50px 0;
        margin-top: 100px;
    }

    .home {
        position: relative;
        font-size: 22px;
        color: #FFF;
        font-family: sans-serif, Helvetica, SimSun, LiSu;
        height: 47px;
        background: #333333;
    }

    .logo {
        position: absolute;
        left: 30px;
        width: 40px;
        height: 40px;
        top: 50%;
        margin-top: -20px;
    }

    .logo > a > img {
        width: 100%;
    }

    .my_blog {
        position: absolute;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-items: center;
        width: 250px;
        right: 10px;
        line-height: 45px;
    }

    .my_blog a {
        color: white;
        text-decoration: none;
    }

    .my_blog a:hover {
        color: #DBDBDB;
        text-decoration: none;
    }

    .my_blog a:focus {
        color: white;
        text-decoration: none
    }

    .my_blog > div {
        width: 80px;
        text-align: center;
        flex: 1;
    }

    .un-link {
        height: 2px;
        background: yellow;
        transition: 0.3s;
        width: 0;
        margin: 0 auto;
    }

    .my_blog > div:hover .un-link {
        width: 70%;

    }

    .column {
        padding: 1%;
        height: 25%;
    }

    .row_box {
        border: 1px solid #DBDBDB;
        border-radius: 10px;
        padding: 5%;
        cursor: pointer;
    }

    .row_box:hover {
        box-shadow: 5px 5px 10px -2px #555555;
    }

    .trigger {
        position: absolute;
        left: 50%;
        -webkit-transform: translate3d(-50%, -10px, 0);
        bottom: 15px;
        animation: floatDown 1s linear 0.5s infinite alternate;
    }

    .trigger img {
        transition: all 0.5s;
    }

    .trigger img:hover {
        border: 1px solid #DBDBDB;
        border-radius: 100px;
        -webkit-transform: rotate(360deg);
    }

    @-webkit-keyframes floatDown {
        to {
            width: auto;
            height: auto;
            -webkit-transform: translate3d(-50%, 0, 0);
        }
    }
    /*header结束*/


    .c-share {
        z-index: 10;
        position: absolute;
        width: 2em;
        height: 2em;
        top: 50%;
        right: 270px;
        background: #333333;
        margin-top: -1em;
        display: none;
    }

    .c-share__input {
        display: none;
    }

    .c-share__input:checked ~ .c-share_options {
        width: 9.2em;
        height: 10.5em;
        border-radius: 10px;
    }

    .c-share__input:checked ~ .c-share_options::before,
    .c-share__input:checked ~ .c-share_options li {
        transition: 0.3s 0.15s;
        opacity: 1;
        transform: translateY(0);
    }

    .c-share_options {
        position: absolute;
        right: 0;
        width: inherit;
        height: inherit;
        border-radius: 50%;
        background-color: #333333;
    }

    .c-share_options a {
        color: #DBDBDB;
    }

    .c-share_options a:hover {
        color: white;
    }

    .c-share__toggler {
        position: absolute;
        cursor: pointer;
        z-index: 1;
        width: 2em;
        height: 2em;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .c-tools {
        width: 1.2em;
    }

    .c-share_options {
        list-style: none;
        overflow: hidden;
        transition: 0.2s;
    }

    .c-share_options::before {
        content: attr(data-title);
        display: block;
        margin: 0.5em 1.25em;
        font-weight: 500;
    }

    .c-share_options li {
        font-size: 0.75em;
        color: #EEE;
        cursor: pointer;
        margin: 0 1.25em;
    }

    .c-share_options li:not(:last-child) {
        margin-bottom: 0.75em;
    }

    .c-share_options::before,
    .c-share_options li {
        opacity: 0;
        transform: translateY(0.625em);
        transition: 0s;
    }

    /*第一屏开始*/
    #onePage {
        background: #EEE;
        overflow: hidden;
        min-height: 700px;
        height: auto;
        padding: 5vw;
        text-align: center;
    }

    .book-title {
        margin: 5vh 0;
    }

    .book-box {
        min-width: 320px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
    }

    .book-box div {
        text-align: center;
        background: #FFF;
        width: 350px;
        height: 400px;
        margin: 10px;
        padding: 10px;
        border-radius: 10px;
        color: #333333;
        cursor: pointer;
    }

    .book-box div:hover {
        box-shadow: 5px 5px 10px -2px #888888;
        transition: border-color .15s, box-shadow .15s;
    }

    .book-box div img {
        margin-top: 60px;
    }

    .book-box button {
        border: none;
    }

    /*第一屏结束*/

    /*第三屏开始*/
    .works-list {
        bottom: 0;
        margin: 0 auto;
        display: flex;
        width: 100%;
        height: 40vw;
        background: #444;
        flex-flow: row nowrap;
        align-items: center;
    }

    .works-list a {
        color: #DBDBDB;
    }

    .works-list > div {
        position: relative;
        width: 20vw;
        height: 20vw;
        overflow: hidden;
        cursor: pointer;
    }

    .works-list > div > img {
        width: 100%;
        height: 100%;
        transition: all 0.5s;
    }

    .works-title:hover img {
        transform: scale(1.2);
    }

    .works-title:hover .title {
        width: 100%;
        height: 100%;
    }

    .works-title > div {
        position: absolute;
        z-index: 2;
        width: 0;
        height: 0;
        left: 50%;
        top: 50%;
        transform: translate3d(-50%, -50%, 0);
        text-align: center;
        line-height: 20vw;
        background: #333333;
        opacity: 0.9;
        color: white;
        font-size: 20px;
        font-weight: bold;
        border-radius: 50%;
        transition: all 0.7s;
        overflow: hidden;
    }

    /*第三屏结束*/

    /*最后一屏*/
    #endPage {
        width: 100%;
        bottom: 0;
        background: #333333;
        text-align: center;
        color: #999;
        font-size: 18px;
        font-weight: 400;
    }

    .user-links {
        display: none;
        margin-top: 10px;
        height: 50px;
        text-align: center;
    }

    .user-links > ul {
        margin: 0 auto;
        white-space: nowrap;
        width: 100%;
    }

    .user-links > ul > li {
        height: 48px;
        width: 48px;
        display: inline;
        cursor: pointer;
    }

    .link_box {
        position: relative;
        display: inline-block;
    }

    .qq_link {
        visibility: hidden;
        width: 100px;
        position: absolute;
        background-color: #EEE;
        border-radius: 6px;
        padding: 5px;
        left: 50%;
        transform: translateX(-129px);
        bottom: 150%;
    }

    .qq_link::after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border: 5px solid transparent;
        border-top-color: #EEE;
    }

    .weixin_link {
        visibility: hidden;
        width: 100px;
        position: absolute;
        background-color: #EEE;
        border-radius: 6px;
        padding: 5px;
        left: 50%;
        transform: translateX(-77px);
        bottom: 150%;
    }

    .weixin_link::after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border: 5px solid transparent;
        border-top-color: #EEE;
    }

    .qq_link > img {
        width: 100%;
    }

    .weixin_link > img {
        width: 100%;
    }

    .beian_line p {
        margin: 5px 0;
    }

    .beian_font {
        font-size: 12px;
        margin: 10px
    }

    .beian_font a {
        color: white;
        text-decoration: none;
    }

    .beian_font a:hover {
        color: #DBDBDB;
        text-decoration: none;
    }

    .beian_font a:focus {
        color: white;
        text-decoration: none
    }

    .beian_img {
        vertical-align: middle;
    }
}