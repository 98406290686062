#main_box{
  width: 100vw;
  height: 92vh;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

/*自定义滚动条*/
#file_list::-webkit-scrollbar {
  width: 8px;
}

#file_list::-webkit-scrollbar-track {
  background-color: #eee;
}

#file_list::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #3DB6A4;
}

a{
  color: #0a8ddf;
  text-decoration: none;
}
.upload_btn{
  width: 100px;
  height: 100px;
  text-align: center;
  background: #DFDFDF;
  color: #000000;
  border-radius: 10%;
  border: solid 1px #333333;
  cursor: pointer;
}
.upload_btn:hover {
  box-shadow: 1px 1px 5px -2px #333;
}
.file_list{
  position: relative;
  overflow-y:scroll;
  width: 25vw;
  min-width: 300px;
  height: 92vh;
  padding: 10px 18px 10px 10px;
  background-color: #f2f2f2;
}
.file_upload{
  position: relative;
  width: 70vw;
  min-width: 300px;
  height: 92vh;
  padding-right: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.file_item{
  padding: 5px 10px;
  box-shadow: 1px 1px 5px -2px #333;
  border-radius: 5px;
  margin: 10px 0;
}
.progress{
  margin: 20px 0;
  width: 60%;
}
.file_name{
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
}